import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { addNewData, deleteData, getAllListData, updateData } from 'services/CommonServices';
import PageLoader from 'components/PageLoader';
import { useAppSelector } from 'store/hooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import HandleRolesAndPermissions from './HandleRolesAndPermission';
import _ from 'lodash';
import Storage from 'utils/Storage';
import { useDispatch } from 'react-redux';
import { ROLE_MASTER } from 'store/types/UserTypes';
import { Search } from '@mui/icons-material';
import CheckIconClassCode from '@mui/icons-material/Check';
import { useDebounce } from 'use-debounce';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 6,
  width: 24,
  height: 24,
  // boxShadow:
  //   theme.palette.mode === 'dark'
  //     ? '0 0 0 1px rgb(16 22 26 / 40%)'
  //     : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#EFEFEF' : '#EFEFEF',
  // backgroundImage:
  //   theme.palette.mode === 'dark'
  //     ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
  //     : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '1px auto #D9D9D9',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#EFEFEF' : '#EFEFEF',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#0061FF',
  // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#0061FF',
  },
});

const RolesandPermissions = () => {
  const dispatch: any = useDispatch();
  const inputRef = useRef(null);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [roleList, setRoleList] = React.useState<any>([]);
  const [roleId, setRoleId] = React.useState<string>('');
  const [editRoleObject, setEditRoleObject] = React.useState<any>({});
  const [roleName, setRoleName] = React.useState<string>('');
  const [roleNameOriginal, setRoleNameOriginal] = React.useState<string>('');
  const [roleDefault, setRoleDefault] = React.useState<boolean>(false);
  const [classificationCode, setClassificationCode] = React.useState<any>([]);
  const [classCodeId, setClassCodeId] = React.useState<string>('');
  const [roleScreen, setRoleScreen] = React.useState<any>([
    {
      isAccessible: true,
      moduleId: 1,
      screenId: 1,
      screenName: 'Dashboard',
    },
  ]);

  const [roleClasses, setRoleClasses] = React.useState<any>([]);
  const [hanldeRole, setHandleRole] = React.useState<any>({
    visible: false,
    type: '',
  });
  const { userInfo: userDetail, roleMasterData }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });

  const getRolesMaster = async () => {
    setLoader(true);
    const endPoint = `${MASTER_ENDPOINT.GetModules}`;
    await getAllListData(endPoint).then((response: any) => {
      if (roleMasterData.length === 0 || !_.isEqual(response?.data, roleMasterData)) {
        Storage.removeItem(Storage.KEYS.ROLE_MASTER);
        dispatch({
          type: ROLE_MASTER,
          payload: response?.data,
        });
        Storage.setItem(Storage.KEYS.ROLE_MASTER, JSON.stringify(response?.data));
      }
    });
    const endPointClassCode = `${MASTER_ENDPOINT.ClassificationCode}/${userDetail.ClientId}`;
    await getAllListData(endPointClassCode).then(async (response: any) => {
      if (response?.data?.length) {
        setClassCodeId(response?.data?.[0]?.classificationCode);
        response.data = await response.data.map((item: any) => {
          const endPoint1 = `${MASTER_ENDPOINT.ClassificationCodeDetails}/${userDetail?.ClientId}/${item.classificationCode}`;
          getAllListData(endPoint1).then((response: any) => {
            item.list = response?.data ? response?.data : [];
          });
          return item;
        });
      }
      setClassificationCode(response?.data);
    });
    await getRoleData();
    setLoader(false);
  };

  const getRoleData = async () => {
    const endPoint1 = `${MASTER_ENDPOINT.GetRoles}/${userDetail?.ClientId}`;
    await getAllListData(endPoint1).then((response: any) => {
      setRoleList(response?.data.filter((row: any) => row.id !== 1));
    });
  };

  const [inputValue, setInputValue] = React.useState('');
  const [debouncedValue] = useDebounce(inputValue, 1000);

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setInputValue(value);
  };

  useEffect(() => {
    getRolesMaster();
  }, []);

  const setScreenFun = (mId: number, sId: number, sName: string, check: boolean) => {
    const fIndex = roleScreen.findIndex((item: any) => item.screenId === sId);
    if (fIndex !== -1) {
      roleScreen[fIndex].isAccessible = check;
    } else {
      roleScreen.push({
        moduleId: mId,
        screenId: sId,
        screenName: sName,
        isAccessible: check,
      });
    }
    setRoleScreen([...roleScreen]);
  };

  const onCheckOverAll = (checked: boolean, list: any, moduleId: number) => {
    list.map((item: any) => {
      setScreenFun(moduleId, item.screenId, item.screenName, checked);
    });
  };

  const renderScreenAndModules = (item: any, item1: any, index1: number) => {
    if (item.module_Id === 1) {
      return item1?.moduleList.map((item2: any) => {
        let isAccessible = false;
        const fIndex = roleScreen.findIndex((row: any) => row.screenId === item2.screenId);
        if (fIndex !== -1) {
          isAccessible = roleScreen[fIndex].isAccessible;
        }
        return (
          <Grid item md={6} sm={12} xs={12} key={item1.module_Id + '-' + index1}>
            <Typography component={'div'} className='h-44 d-flex-a'>
              <FormControlLabel
                label={item2.screenName}
                className='parent-checkbox'
                control={
                  <Checkbox
                    className='custom-checkbox'
                    sx={{
                      '&:hover': { bgcolor: 'transparent' },
                    }}
                    checked={item2.screenId === 1 ? true : isAccessible}
                    onClick={(e: any) =>
                      item2.screenId !== 1 &&
                      setScreenFun(
                        item.module_Id,
                        item2.screenId,
                        item2.screenName,
                        e.target.checked,
                      )
                    }
                    disableRipple
                    color='default'
                    checkedIcon={<CheckedIcon />}
                    icon={<CheckIcon />}
                  />
                }
              />
            </Typography>
          </Grid>
        );
      });
    } else {
      const filterList = item1?.moduleList.filter((row: any) => {
        const fIndex = roleScreen.findIndex((row2: any) => row2.screenId === row.screenId);
        return fIndex === -1 ? false : roleScreen[fIndex].isAccessible;
      });
      return (
        <Grid item md={6} sm={12} xs={12} key={item1.module_Id + '-' + index1}>
          <Typography component={'div'} className={index1 === 0 || index1 === 1 ? '' : 'mt-20'}>
            <FormControlLabel
              label={item1?.subModuleName}
              className='parent-checkbox'
              control={
                <Checkbox
                  checked={
                    filterList.length === 0
                      ? false
                      : filterList.length === item1.moduleList.length
                        ? true
                        : false
                  }
                  className='custom-checkbox'
                  sx={{
                    '&:hover': { bgcolor: 'transparent' },
                  }}
                  disableRipple
                  onClick={(e: any) =>
                    onCheckOverAll(e.target.checked, item1.moduleList, item.module_Id)
                  }
                  color='default'
                  checkedIcon={<CheckedIcon />}
                  icon={<CheckIcon />}
                />
              }
            />
            <Box
              component='div'
              className='ml-40'
              sx={{ display: 'flex', flexDirection: 'column' }}>
              {item1?.moduleList.map((item2: any) => {
                let isAccessible = false;
                const fIndex = roleScreen.findIndex((row: any) => row.screenId === item2.screenId);
                if (fIndex !== -1) {
                  isAccessible = roleScreen[fIndex].isAccessible;
                }
                return (
                  <FormControlLabel
                    key={item2?.screenId}
                    label={item2?.screenName}
                    control={
                      <Checkbox
                        className='custom-checkbox'
                        sx={{
                          '&:hover': { bgcolor: 'transparent' },
                        }}
                        checked={isAccessible}
                        onClick={(e: any) =>
                          setScreenFun(
                            item.module_Id,
                            item2.screenId,
                            item2.screenName,
                            e.target.checked,
                          )
                        }
                        disableRipple
                        color='default'
                        checkedIcon={<CheckedIcon />}
                        icon={<CheckIcon />}
                      />
                    }
                  />
                );
              })}
            </Box>
          </Typography>
        </Grid>
      );
    }
  };

  const cancelFunction = () => {
    setRoleId('');
    setRoleName('');
    setRoleNameOriginal('');
    setRoleDefault(false);
    setRoleScreen([
      {
        isAccessible: true,
        moduleId: 1,
        screenId: 1,
        screenName: 'Dashboard',
      },
    ]);
    setRoleClasses([]);
    setEditRoleObject({});
    const inputElement = document.getElementById('standard-basic');
    if (inputElement) {
      inputElement.focus();
    }
  };

  const submitRole = () => {
    if (!roleName.trim()) {
      toast.error('Please add role name');
      return;
    }
    if (roleName.trim().length < 2) {
      toast.error('Please add Atleast 2 Characters');
      return;
    }
    const roleScreenValidate = roleScreen.filter((row: any) => row.isAccessible);
    if (roleScreenValidate.length === 0) {
      toast.error('Please add atleast one screen');
      return;
    }
    if (roleId) {
      setLoader(true);
      const postData = {
        roleId: roleId,
        clientId: editRoleObject.clientID,
        active: editRoleObject.active,
        roleName: roleName,
        roleType: '',
        isDefault: roleDefault,
        screens: roleScreen,
        classes: roleClasses,
        createdBy: editRoleObject.createdBy,
        createdOn: editRoleObject.createdOn,
        updatedBy: userDetail.Id,
        updatedOn: moment(),
      };
      updateData('', postData, `${MASTER_ENDPOINT.UpdateRole}`, true)
        .then(() => {
          getRoleData();
          // cancelFunction();
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      setLoader(true);
      const postData = {
        clientId: userDetail.ClientId,
        active: 0,
        roleName: roleName,
        roleType: '',
        isDefault: roleDefault ? true : false,
        screens: roleScreen,
        classes: roleClasses,
        createdBy: userDetail.Id,
        createdOn: moment(),
        updatedBy: userDetail.Id,
        updatedOn: moment(),
      };
      addNewData(postData, `${MASTER_ENDPOINT.CreateRole}`, {}, true)
        .then((resp: any) => {
          getRoleData();
          cancelFunction();
          onClickRow(resp?.data);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const cloneRole = () => {
    if (roleName) {
      if (editRoleObject.roleName.trim() === roleName.trim()) {
        toast.error('Please rename and clone the role');
        return true;
      }
      if (roleName.trim().length < 2) {
        toast.error('Please Add Atleast 2 characters');
        return true;
      }
      setLoader(true);
      const postData = {
        clientId: userDetail.ClientId,
        active: 0,
        roleName: roleName,
        roleType: '',
        isDefault: false,
        screens: roleScreen,
        classes: roleClasses,
        createdBy: userDetail.Id,
        createdOn: moment(),
        updatedBy: userDetail.Id,
        updatedOn: moment(),
      };
      addNewData(postData, `${MASTER_ENDPOINT.CreateRole}`, {}, true)
        .then((resp: any) => {
          getRoleData();
          closeModal();
          cancelFunction();
          onClickRow(resp?.data);
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      toast.error('Please add role name');
      return;
    }
  };

  const onClickRow = async (row: any) => {
    if(row?.id){
    setLoader(true);
    const endPoint = `${MASTER_ENDPOINT.GetRoleById}/${row?.id}`;
    await getAllListData(endPoint).then((response: any) => {
      setRoleDefault(row.isDefault);
      setRoleName(row.roleName);
      setRoleNameOriginal(row.roleName);
      setRoleScreen(
        response?.data?.screens && response?.data?.screens?.length
          ? response?.data?.screens
          : [
              {
                isAccessible: true,
                moduleId: 1,
                screenId: 1,
                screenName: 'Dashboard',
              },
            ],
      );
      setRoleClasses(response?.data?.classes ? response?.data?.classes : []);
      setEditRoleObject(row);
      setRoleId(row?.id);
    });
    setLoader(false);
  }
  };

  const deleteRole = () => {
    setLoader(true);
    deleteData(roleId + '/' + userDetail.Id, MASTER_ENDPOINT.DeleteRole, true)
      .then(() => {
        getRoleData();
        closeModal();
        cancelFunction();
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const openModal = (str: string) => {
    setHandleRole({
      visible: true,
      type: str,
    });
  };

  const closeModal = () => {
    setHandleRole({
      visible: false,
      type: '',
    });
    setRoleName(roleNameOriginal);
  };

  const classCodeContentLis = () => {
    const filterItem = classificationCode.find(
      (item: any) => item.classificationCode === classCodeId,
    );
    if (filterItem && filterItem?.list && filterItem?.list?.length) {
      let data = filterItem?.list;
      if (debouncedValue) {
        data = filterItem?.list.filter((row: any) =>
          (row.description ? row.description.toLowerCase() : '').includes(
            debouncedValue.toLowerCase(),
          ),
        );
      }
      if (data?.length) {
        return data.map((item: any, index: number) => {
          const findItem = roleClasses.findIndex(
            (row: any) => row.id === item.id && row.class === item.class,
          );
          return (
            <Typography
              component={'div'}
              key={index}
              onClick={() => {
                if (findItem !== -1) {
                  roleClasses.splice(findItem, 1);
                  // roleClasses[findItem].active = !roleClasses[findItem].active;
                } else {
                  roleClasses.push({
                    code: item?.code,
                    id: item?.id,
                    active: true,
                    class: classCodeId,
                  });
                }
                setRoleClasses([...roleClasses]);
              }}
              justifyContent={'space-between'}
              className={
                findItem !== -1 ? 'class-code-list-view list-view-active' : 'class-code-list-view'
              }
              display={'flex'}>
              <Typography component={'div'} className='class-code-list-text'>
                {item.description}
              </Typography>
              {findItem !== -1 && (
                <Typography component={'div'} className='class-code-list-text'>
                  <CheckIconClassCode sx={{ color: '#0061ff' }} />
                </Typography>
              )}
            </Typography>
          );
        });
      } else {
        return (
          <Typography component={'div'} className='class-code-list-text d-flex-ja'>
            No Records Found
          </Typography>
        );
      }
    }
    return;
  };

  return (
    <Box component='div'>
      <PageLoader loading={loader} />
      <Grid container className='border-bottom bg-white'>
        <Grid item md={3} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='d-flex-a flex-sb px-17 py-16'
            onClick={cancelFunction}>
            <Typography className='role-title'>Roles</Typography>
            <Button className='tool-add-btn' sx={{ width: '106px !important' }}>
              {' '}
              + ROLE
            </Button>
          </Typography>
        </Grid>
        <Grid
          item
          md={9}
          sm={12}
          xs={12}
          className='px-17 py-16'
          sx={{ paddingLeft: '12px !important' }}>
          <Grid container>
            <Grid item md={5.6} sm={6} xs={6}>
              <Typography className='private-form'>
                <Typography
                  component={'div'}
                  className='custom-field d-flex-a'
                  sx={{ marginBottom: '0px !important' }}>
                  <TextField
                    className='custom-big-input'
                    id='standard-basic'
                    placeholder='Enter Role*'
                    variant='standard'
                    ref={inputRef}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trimStart();
                      setRoleName(trimmedValue);
                    }}
                    value={roleName ? roleName : ''}
                  />
                  {roleId ? (
                    <>
                      <Button
                        className='n-btn'
                        onClick={() =>
                          openModal(editRoleObject?.isDefault ? 'deleteDefault' : 'delete')
                        }
                        sx={{ minWidth: '110px !important' }}>
                        DELETE ROLE
                      </Button>
                      <Button
                        className='n-btn'
                        onClick={() => {
                          openModal(roleDefault ? 'cloneDefault' : 'clone');
                        }}>
                        CLONE
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button className='n-btn' />
                      <Button className='n-btn' />
                    </>
                  )}
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={6.4} sm={6} xs={6} className='flex-end d-flex-a'>
              <Typography className='d-flex-a normal-text'>
                Default Role
                <Switch
                  className='custom-switch'
                  checked={roleDefault}
                  onChange={(e) => setRoleDefault(e.target.checked)}
                />
              </Typography>
              <Button className='cancel-btn mr-10' onClick={cancelFunction}>
                CANCEL
              </Button>
              <Button className='save-btn' onClick={submitRole}>
                {roleId ? 'UPDATE' : 'SAVE'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className='h-161'>
        <Grid item md={3} sm={12} xs={12} className='bg-white role-sidebox'>
          <Typography component={'div'}>
            {roleList.map((item: any, index: number) => {
              if (item?.isDefault) {
                return (
                  <Typography
                    component={'div'}
                    className={roleId === item.id ? 'role-item role-acitive' : 'role-item'}
                    key={index}
                    onClick={() => onClickRow(item)}>
                    <Typography component={'div'}>
                      <Typography className={'role-italic'}>Default Role</Typography>
                      <Typography className='div'>{item?.roleName}</Typography>
                    </Typography>
                  </Typography>
                );
              }
              return (
                <Typography
                  component={'div'}
                  className={roleId === item.id ? 'role-item role-acitive' : 'role-item'}
                  key={index}
                  onClick={() => onClickRow(item)}>
                  {item?.roleName}
                </Typography>
              );
            })}
          </Typography>
        </Grid>
        <Grid item md={9} sm={12} xs={12}>
          <Typography component={'div'} className='role-contentbox'>
            {roleMasterData.map((item: any, index: number) => {
              return (
                <Typography
                  component={'div'}
                  className={index === 0 ? 'permission-box' : 'permission-box mt-20'}
                  key={index}>
                  <Typography className='per-title'>{item.moduleName}</Typography>
                  <Grid container>
                    {item?.screensList &&
                      item?.screensList.map((item1: any, index1: number) => {
                        return renderScreenAndModules(item, item1, ((100 * index) + index1));
                      })}
                    {item.module_Id === 1 && (
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography className='warn-text-15'>
                          *Warning: This role provides access to compensation data
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Typography>
              );
            })}
          </Typography>
          <Typography component={'div'} className='role-class-code-contentbox'>
            <Typography component={'div'} className={'permission-box'}>
              <Typography component={'div'} className='per-title'>
                Classification Criteria
              </Typography>
              <Grid container>
                <Grid
                  item
                  md={4}
                  sm={12}
                  xs={12}
                  sx={{ maxHeight: '330px !important', overflowY: 'scroll' }}>
                  {classificationCode.map((item: any, index: number) => {
                    const outOfFilter = roleClasses.filter(
                      (row: any) => row.class === item.classificationCode,
                    );
                    return (
                      <Typography
                        component={'div'}
                        onClick={() => setClassCodeId(item.classificationCode)}
                        className={
                          item.classificationCode === classCodeId
                            ? 'class-code-div active'
                            : 'class-code-div'
                        }
                        key={index}>
                        <Typography component={'div'} className='class-code-name'>
                          {item?.mapping}
                        </Typography>
                        <Typography component={'div'} className='class-code-count'>
                          {outOfFilter.length} of {item?.list ? item?.list?.length : 0}
                        </Typography>
                      </Typography>
                    );
                  })}
                </Grid>
                <Grid
                  item
                  md={8}
                  sm={12}
                  xs={12}
                  sx={{ maxHeight: '330px !important', overflowY: 'scroll' }}>
                  <Typography component={'div'} sx={{ padding: '10px 15px' }}>
                    <TextField
                      type={'search'}
                      fullWidth
                      variant='standard'
                      placeholder={
                        classCodeId
                          ? `Search ${classificationCode.find(
                              (e: any) => e.classificationCode === classCodeId,
                            )?.mapping}`
                          : 'Search Company...'
                      }
                      className='input-field'
                      onChange={handleInputChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography component={'div'} className='class-code-list-box'>
                      {classCodeContentLis()}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <HandleRolesAndPermissions
        handleClose={closeModal}
        onConfirm={() => {
          if (hanldeRole.type === 'delete') {
            deleteRole();
          } else if (hanldeRole.type === 'clone' || hanldeRole.type === 'cloneDefault') {
            cloneRole();
          } else {
            closeModal();
          }
        }}
        visible={hanldeRole.visible}
        type={hanldeRole.type}
        roleName={roleName}
        setRoleName={setRoleName}
      />
    </Box>
  );
};

export default RolesandPermissions;
