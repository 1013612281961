import React, { useEffect } from 'react';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks'; 

interface IFilterDataContextType {
    filterData: any,
    setFilterData: (data: any) => void;
    showNumbers: boolean,
    setShowNumbers: (data: boolean) => void;
    showCharts: boolean,
    setShowCharts: (data: boolean) => void;
}

const defaultSearchData = {
    filterData: {},
    setFilterData: function (data: any) { console.log(data) },
    showNumbers: false,
    setShowNumbers: function (data: boolean) { console.log(data) },
    showCharts: false,
    setShowCharts: function (data: boolean) { console.log(data) }
}

export const FilterDataContext = React.createContext<IFilterDataContextType>(defaultSearchData);

type FilterDataContextProviderProps = {
    children: any;
};

const FilterDataContextProvider = (props: FilterDataContextProviderProps) => {

    const userDetail: any = useAppSelector((store: any) => {
        return store.userLogin.userInfo;
    });

    useEffect(() => {
         
        getAllListData(`${MASTER_ENDPOINT.GetFilter}/${userDetail.Id}`).then(async (response: any) => {
           
            if (response?.data) {
                const jobData = (response?.data?.jobs ? response.data.jobs : []).map((item: any) => {
                    return item?.jobCode;
                });
                const companyData = (response?.data?.companies ? response.data.companies : []).map(
                    (item: any) => {
                        return item?.companyCode;
                    },
                );
                const directSuperVisorData = (
                    response?.data?.directSupervisors ? response.data.directSupervisors : []
                ).map((item: any) => {
                    return item?.directSupervisorEmployeeID;
                });

                const codeData = response.data.classes;

                setFilterData((pre: any) => {
                    return { ...pre, codeList: codeData,
                        jobList: jobData,
                        companyList: companyData,
                        directSuperVisorList: directSuperVisorData };
                });             
            }
        })
    }, []); 
  
    const fltData = {
        codeList: [],
        jobList: [],
        companyList: [],
        directSuperVisorList: []
    };

  
    const [filterData, setFilterData] = React.useState(fltData);

    const [showNumbers, setShowNumbers] = React.useState(true);
    const [showCharts, setShowCharts] = React.useState(false);

    const data = {
        filterData,
        setFilterData,
        showNumbers,
        setShowNumbers,
        showCharts,
        setShowCharts
    };

    return (
        <FilterDataContext.Provider value={data} >{props.children}</FilterDataContext.Provider>
    );
};

export default FilterDataContextProvider;