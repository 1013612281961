import React from 'react';
import { Navigate, Route, Routes, To, useNavigate } from 'react-router-dom';
import { PrivateRoutes as PrivateRoutesDirectives, PublicRoutes } from './routeConstans';
import Dashboard from 'pages/private/dashboard';
import PrivateLayout from 'pages/private';
import Oraganization from 'pages/private/organization';
import EmployeeEgage from 'pages/private/organizationalstructure';
import EmployeeEgageList from 'pages/private/organizationalstructure/list';
import EmployeeEngagementSummary from 'pages/private/employeeengagment';
import FilterDataContextProvider from 'context/FilterDataContextProvider';
import Performance from 'pages/private/performance';
import Accessmanagement from 'pages/private/accessmanagement';
import Recruiting from 'pages/private/recruiting';
import Dei from 'pages/private/dei';
import LearnDevlopment from 'pages/private/learndevelopment';
import Reports from 'pages/private/reports';
import Settings from 'pages/private/settings';
import RolesandPermissions from 'pages/private/accessmanagement/rolesandpermissions';
import { logout } from 'store/actions/UserAction';
import { toast } from 'react-toastify';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { alertOptions } from 'utils/Config';
import { useAppSelector } from 'store/hooks';
import EmployeeSearchContextProvider from 'context/EmployeeSearchContextProvider';

const PrivateRoutes = () => {
  const dispatch: any = useDispatch();
  const {
    DASHBOARD,
    ORGANIZATION,
    ORGANIZATIONALSTRUCTURE,
    ORGANIZATIONALSTRUCTURELIST,
    EMPLOYEEENGAGEMENT,
    PERFORMANCE,
    ACCESSMANAGEMENT,
    RECRUITING,
    SETTINGS,
    DEI,
    REPORTS,
    LEARNDEVLOPMENT,
  } = PrivateRoutesDirectives;

  const { userInfo: userDetail }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });

  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const handleIdle = () => {
    logout(dispatch);
    if (userDetail?.isImpersonate) {
      setTimeout(() => gotoNavigate(PublicRoutes.ADMINLOGIN), 0);
    }
    else{
      setTimeout(() => gotoNavigate(PublicRoutes.CLIENTLOGIN), 0);
    }
    toast.error('Session expired! Please login again.', alertOptions);
  };
  useIdleTimer({
    timeout: (45 * 60 * 1000),
    onIdle: handleIdle,
    debounce: 500,
  });
  return (
    <Routes>
      <Route path='/' element={<Navigate to={DASHBOARD} replace />} />
      <Route
        path={DASHBOARD}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={DASHBOARD} title={'DashBoard'} header={true}>
              <Dashboard />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />
      <Route
        path={ORGANIZATION}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={ORGANIZATION} title={'Organizational Overview'} header={true}>
              <Oraganization />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />
      <Route
        path={ORGANIZATIONALSTRUCTURE}
        element={
          <FilterDataContextProvider>
            <EmployeeSearchContextProvider>
              <PrivateLayout path={ORGANIZATIONALSTRUCTURE} title={'Organizational Structure'} header={true}>
                <EmployeeEgage />
              </PrivateLayout>
            </EmployeeSearchContextProvider>
          </FilterDataContextProvider>
        }
      />
      <Route
        path={ORGANIZATIONALSTRUCTURELIST}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={ORGANIZATIONALSTRUCTURELIST} title={'Organizational Structure'} header={true}>
              <EmployeeEgageList />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />
      <Route
        path={EMPLOYEEENGAGEMENT}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={EMPLOYEEENGAGEMENT} title={'Employee Engagement'} header={true}>
              <EmployeeEngagementSummary />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />
      <Route
        path={RECRUITING}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={RECRUITING} title={'Recruiting'} header={true}>
              <Recruiting />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />
      <Route
        path={PERFORMANCE}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={PERFORMANCE} title={'Performance & Comp'} header={true}>
              <Performance />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />
      <Route
        path={DEI}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={DEI} title={'Diversity, Equity & Inclusion'} header={true}>
              <Dei />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />
      <Route
        path={SETTINGS}
        element={
          <PrivateLayout path={SETTINGS} header={false}>
            <Settings />
          </PrivateLayout>
        }
      />
      <Route
        path={LEARNDEVLOPMENT}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={LEARNDEVLOPMENT} title={'Learning & Development'} header={true}>
              <LearnDevlopment />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />
      <Route
        path={REPORTS}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={REPORTS} title={'Reports'} header={true}>
              <Reports />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />
      <Route
        path={ACCESSMANAGEMENT.EMPLOYEES}
        element={
          <FilterDataContextProvider>
            <PrivateLayout
              path={ACCESSMANAGEMENT.EMPLOYEES}
              title={'Access Management'}
              header={true}>
              <Accessmanagement />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />
      <Route
        path={ACCESSMANAGEMENT.ROLESPERMISSIONS}
        element={
          <PrivateLayout
            path={ACCESSMANAGEMENT.ROLESPERMISSIONS}
            title={'Access Management'}
            header={true}>
            <RolesandPermissions />
          </PrivateLayout>
        }
      />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default PrivateRoutes;
