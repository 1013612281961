import React from 'react';
import { Grid, Typography } from '@mui/material';
import ReportModal from './reportModal';
import {
  CompaRation,
  CostOfTurnOver,
  CourseComplete,
  CourseOverdue,
  CoursesAssigned,
  EmployeeDemographics,
  EmployeeEthnicity,
  EmployeeGender,
  HeadCountMonthOverMonthDetails,
  HeadCountWDetails,
  HiresvsTerms,
  LastPreformance,
  NextPreformance,
  OpenRequisition,
  OverallBreakdown,
  OverallSurveyResults,
  PayEthnicity,
  PayGender,
  ScoresbyCategory,
  SurveySubmissionRate,
  TenureRace,
  TimetoFill,
  TurnOverDetails,
  hiredCandidates,
  offeredStart,
  organizationalchart,
} from './tableColumn';
import { useAppSelector } from 'store/hooks';
import * as R from 'ramda';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

// todo: its should change from the api response in future

const organizationIds = [9, 10, 11, 12, 13, 14, 15];
const EEIds = [16, 17, 18, 19, 20, 21];
const RecruitingIds = [22, 23, 24, 25, 26];
const PerformanceIds = [27, 28];
const DiversityIds = [29, 30, 31];
const LearningIds = [32, 33, 34];

const Reports = () => {
  const { menuList: screenList, userInfo: userDetail }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const OraganizationArr = R.filter(
    (t: any) => organizationIds.includes(t.screenId) && t.isAccessible === true,
  )(screenList ? screenList : []);
  const EmployeeArr = R.filter((t: any) => EEIds.includes(t.screenId) && t.isAccessible === true)(
    screenList ? screenList : [],
  );
  const RecruitingArr = R.filter(
    (t: any) => RecruitingIds.includes(t.screenId) && t.isAccessible === true,
  )(screenList ? screenList : []);
  const PerformanceArr = R.filter(
    (t: any) => PerformanceIds.includes(t.screenId) && t.isAccessible === true,
  )(screenList ? screenList : []);
  const DiversityArr = R.filter(
    (t: any) => DiversityIds.includes(t.screenId) && t.isAccessible === true,
  )(screenList ? screenList : []);
  const LearningArr = R.filter(
    (t: any) => LearningIds.includes(t.screenId) && t.isAccessible === true,
  )(screenList ? screenList : []);

  const [reportModal, setReportModal] = React.useState({
    visible: false,
    title: '',
    keyName: '',
    coloumData: [],
  });

  const handleOpen = async (title: string, keyName: string) => {
    let coloumData: any = [];
    if (keyName === 'CompaRatio' || keyName === 'EmployeeDemographics') {
      await getAllListData(`${MASTER_ENDPOINT.ClassificationCode}/${userDetail.ClientId}`).then(
        (resp: any) => {
          coloumData = resp?.data;
        },
      );
    }
    setReportModal({
      visible: true,
      title,
      keyName,
      coloumData,
    });
  };

  const handleClose = () =>
    setReportModal({
      visible: false,
      title: '',
      keyName: '',
      coloumData: [],
    });

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='mt-30 px-87 mb-30'>
          <Grid container columnSpacing={2.5} rowSpacing={2.5}>
            {OraganizationArr.length ? (
              <Grid item md={4} sm={12} xs={12}>
                <Typography component={'div'} className='report-box'>
                  <Typography component={'div'} className='report-head d-flex-ja'>
                    Organizational Overview
                  </Typography>
                  <Typography component={'div'} className='report-body'>
                    {R.findIndex((t: any) => t.screenId === 9 && t.isAccessible === true)(
                      OraganizationArr ? OraganizationArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() => handleOpen('Headcount w/ Details', 'HeadCountWDetails')}>
                        Headcount w/ Details
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 10 && t.isAccessible === true)(
                      OraganizationArr ? OraganizationArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() =>
                          handleOpen(' Month Over Month Turnover', 'TurnoverwDetails')
                        }>
                        Month Over Month Turnover
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 11 && t.isAccessible === true)(
                      OraganizationArr ? OraganizationArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() =>
                          handleOpen(
                            'Headcount Month Over Month w/ Details',
                            'HeadCountMonthOverMonthDetails',
                          )
                        }>
                        Headcount Month Over Month w/ Details
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 12 && t.isAccessible === true)(
                      OraganizationArr ? OraganizationArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() => handleOpen('Terminations with Details', 'CostOfTurnOver')}>
                        Terminations with Details
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 13 && t.isAccessible === true)(
                      OraganizationArr ? OraganizationArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() => handleOpen('Organizational Chart', 'OrganizationalChart')}>
                        Organizational Chart
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 14 && t.isAccessible === true)(
                      OraganizationArr ? OraganizationArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() => handleOpen('Hires vs Terms ', 'HiresvsTermsw/Details')}>
                        Hires vs Terms
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 15 && t.isAccessible === true)(
                      OraganizationArr ? OraganizationArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail '
                        onClick={() =>
                          handleOpen(
                            'Employee Demographics/Personnel Record',
                            'EmployeeDemographics',
                          )
                        }>
                        Employee Demographics/Personnel Record
                      </Typography>
                    )}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <React.Fragment />
            )}
            {EmployeeArr.length ? (
              <Grid item md={4} sm={12} xs={12}>
                <Typography component={'div'} className='report-box'>
                  <Typography component={'div'} className='report-head d-flex-ja'>
                  Employee Engagement
                  </Typography>
                  <Typography component={'div'} className='report-body'>
                    {R.findIndex((t: any) => t.screenId === 16 && t.isAccessible === true)(
                      EmployeeArr ? EmployeeArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail '
                        onClick={() =>
                          handleOpen('Survey Submission Rate', 'SurveySubmissionRate')
                        }>
                        Survey Submission Rate
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 17 && t.isAccessible === true)(
                      EmployeeArr ? EmployeeArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail '
                        onClick={() => handleOpen('Scores by Category', 'ScoresbyCategory')}>
                        Scores by Category (date range)
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 18 && t.isAccessible === true)(
                      EmployeeArr ? EmployeeArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() =>
                          handleOpen('Overall Survey Results', 'OverAllSurveyResults')
                        }>
                        Overall Survey Results
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 19 && t.isAccessible === true)(
                      EmployeeArr ? EmployeeArr : [],
                    ) !== -1 && (
                      <Typography className='rb-detail rb-detail-disabled'>
                        At Risk for Leaving
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 20 && t.isAccessible === true)(
                      EmployeeArr ? EmployeeArr : [],
                    ) !== -1 && (
                      <Typography className='rb-detail rb-detail-disabled'>
                        At Risk for Staying
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 21 && t.isAccessible === true)(
                      EmployeeArr ? EmployeeArr : [],
                    ) !== -1 && (
                      <Typography className='rb-detail rb-detail-disabled'>
                        Employee Health
                      </Typography>
                    )}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <React.Fragment />
            )}
            {RecruitingArr.length ? (
              <Grid item md={4} sm={12} xs={12}>
                <Typography component={'div'} className='report-box'>
                  <Typography component={'div'} className='report-head d-flex-ja'>
                    Recruiting
                  </Typography>
                  <Typography component={'div'} className='report-body'>
                    {R.findIndex((t: any) => t.screenId === 22 && t.isAccessible === true)(
                      RecruitingArr ? RecruitingArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail '
                        onClick={() =>
                          handleOpen('Open Requisition w/ Details', 'OpenRequisition')
                        }>
                        Open Requisition w/ Details
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 23 && t.isAccessible === true)(
                      RecruitingArr ? RecruitingArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail '
                        onClick={() => handleOpen('Time to Fill', 'TimetoFill')}>
                        Time to Fill
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 24 && t.isAccessible === true)(
                      RecruitingArr ? RecruitingArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail '
                        onClick={() => handleOpen('Hired Candidates w/Details', 'hiredCandidates')}>
                        Hired Candidates w/Details
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 25 && t.isAccessible === true)(
                      RecruitingArr ? RecruitingArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail '
                        onClick={() => handleOpen('Offered to Started Timeframe', 'offeredStart')}>
                        Offered to Started Timeframe
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 26 && t.isAccessible === true)(
                      RecruitingArr ? RecruitingArr : [],
                    ) !== -1 && (
                      <Typography className='rb-detail rb-detail-disabled'>
                        Onboarding Kick-off to Hired Date
                      </Typography>
                    )}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <React.Fragment />
            )}
            {PerformanceArr.length ? (
              <Grid item md={4} sm={12} xs={12}>
                <Typography component={'div'} className='report-box'>
                  <Typography component={'div'} className='report-head d-flex-ja'>
                    Performance
                  </Typography>
                  <Typography component={'div'} className='report-body'>
                    {R.findIndex((t: any) => t.screenId === 27 && t.isAccessible === true)(
                      PerformanceArr ? PerformanceArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() => handleOpen('Last Performance Score', 'LastPreformance')}>
                        Last Performance Score
                      </Typography>
                    )}
                    {/* {R.findIndex((t: any) => t.screenId === 34 && t.isAccessible === true)(
                      PerformanceArr ? PerformanceArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail rb-detail-disabled'
                        // onClick={() => handleOpen('Next Performance Review', 'NextPreformance')}
                      >
                        Next Performance Review
                      </Typography>
                    )} */}
                    {R.findIndex((t: any) => t.screenId === 28 && t.isAccessible === true)(
                      PerformanceArr ? PerformanceArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() => handleOpen('Compa Ratio', 'CompaRatio')}>
                        Compa Ratio
                      </Typography>
                    )}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <React.Fragment />
            )}
            {DiversityArr.length ? (
              <Grid item md={4} sm={12} xs={12}>
                <Typography component={'div'} className='report-box'>
                  <Typography component={'div'} className='report-head d-flex-ja'>
                    Diversity, Equity & Inclusion
                  </Typography>
                  <Typography component={'div'} className='report-body'>
                    {R.findIndex((t: any) => t.screenId === 29 && t.isAccessible === true)(
                      DiversityArr ? DiversityArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail '
                        onClick={() => handleOpen('Overall DE&I Breakdown', 'OverallBreakdown')}>
                        Overall DE&I Breakdown
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 37 && t.isAccessible === true)(
                      DiversityArr ? DiversityArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() => handleOpen('Employees by Ethnicity', 'EmployeeEthnicity')}>
                        Employees by Ethnicity
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 38 && t.isAccessible === true)(
                      DiversityArr ? DiversityArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() => handleOpen('Employees by Gender', 'EmployeeGender')}>
                        Employees by Gender
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 30 && t.isAccessible === true)(
                      DiversityArr ? DiversityArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() =>
                          handleOpen('Overall DE&I Breakdown with Pay', 'PayEthnicity')
                        }>
                        Overall DE&I Breakdown with Pay
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 40 && t.isAccessible === true)(
                      DiversityArr ? DiversityArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() => handleOpen('Pay by Gender', 'PayGender')}>
                        Pay by Gender
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 31 && t.isAccessible === true)(
                      DiversityArr ? DiversityArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() => handleOpen('Overall Tenure', 'TenureRace')}>
                        Overall Tenure
                      </Typography>
                    )}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <React.Fragment />
            )}
            {LearningArr.length ? (
              <Grid item md={4} sm={12} xs={12}>
                <Typography component={'div'} className='report-box'>
                  <Typography component={'div'} className='report-head d-flex-ja'>
                    Learning & Development
                  </Typography>
                  <Typography component={'div'} className='report-body'>
                    {R.findIndex((t: any) => t.screenId === 32 && t.isAccessible === true)(
                      LearningArr ? LearningArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail '
                        onClick={() => handleOpen('Courses Completed', 'CoursesCompleted')}>
                        Courses Completed
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 33 && t.isAccessible === true)(
                      LearningArr ? LearningArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() => handleOpen('Courses Assigned', 'CoursesAssigned')}>
                        Courses Assigned
                      </Typography>
                    )}
                    {R.findIndex((t: any) => t.screenId === 34 && t.isAccessible === true)(
                      LearningArr ? LearningArr : [],
                    ) !== -1 && (
                      <Typography
                        className='rb-detail'
                        onClick={() => handleOpen('Courses Overdue', 'CoursesOverdue')}>
                        Courses Overdue
                      </Typography>
                    )}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <React.Fragment />
            )}
          </Grid>
        </Typography>
        {reportModal.visible && (
          <ReportModal
            handleClose={handleClose}
            visible={reportModal.visible}
            title={reportModal.title}
            keyName={reportModal.keyName}
            columnArray={
              reportModal.keyName === 'HeadCountMonthOverMonthDetails'
                ? HeadCountMonthOverMonthDetails
                : reportModal.keyName === 'CostOfTurnOver'
                  ? CostOfTurnOver
                  : reportModal.keyName === 'HeadCountWDetails'
                    ? HeadCountWDetails
                    : reportModal.keyName === 'OrganizationalChart'
                      ? organizationalchart
                      : reportModal.keyName === 'HiresvsTermsw/Details'
                        ? HiresvsTerms
                        : reportModal.keyName === 'LastPreformance'
                          ? LastPreformance
                          : reportModal.keyName === 'CoursesCompleted'
                            ? CourseComplete
                            : reportModal.keyName === 'CoursesOverdue'
                              ? CourseOverdue
                              : reportModal.keyName === 'CoursesAssigned'
                                ? CoursesAssigned
                                : reportModal.keyName === 'TurnoverwDetails'
                                  ? TurnOverDetails
                                  : reportModal.keyName === 'CompaRatio'
                                    ? CompaRation.map((e: any) => {
                                        if (
                                          [
                                            'Class Code1',
                                            'Class Code2',
                                            'Class Code3',
                                            'Class Code4',
                                            'Class Code5',
                                          ].includes(e.name)
                                        ) {
                                          const findObjectNumber: any = {
                                            'Class Code1': 1,
                                            'Class Code2': 2,
                                            'Class Code3': 3,
                                            'Class Code4': 4,
                                            'Class Code5': 5,
                                          };
                                          const findNumber = findObjectNumber[e.name];
                                          const findClass: any = reportModal.coloumData.find(
                                            (item: any) => item.classificationCode === findNumber,
                                          );
                                          if (findClass) {
                                            e.name = findClass.mapping;
                                          } else {
                                            findNumber === 1
                                              ? (e.name = 'Department')
                                              : findNumber === 2
                                                ? (e.name = 'Location')
                                                : findNumber === 3
                                                  ? (e.name = 'Division')
                                                  : findNumber === 4
                                                    ? (e.name = 'Shift')
                                                    : findNumber === 5 &&
                                                      (e.name = 'ProjectCostCenter');
                                          }
                                        }
                                        return e;
                                      })
                                    : reportModal.keyName === 'NextPreformance'
                                      ? NextPreformance
                                      : reportModal.keyName === 'EmployeeEthnicity'
                                        ? EmployeeEthnicity
                                        : reportModal.keyName === 'EmployeeGender'
                                          ? EmployeeGender
                                          : reportModal.keyName === 'PayEthnicity'
                                            ? PayEthnicity
                                            : reportModal.keyName === 'PayGender'
                                              ? PayGender
                                              : reportModal.keyName === 'TenureRace'
                                                ? TenureRace
                                                : reportModal.keyName === 'EmployeeDemographics'
                                                  ? EmployeeDemographics.map((e: any) => {
                                                      if (
                                                        [
                                                          'Class Code1',
                                                          'Class Code2',
                                                          'Class Code3',
                                                          'Class Code4',
                                                          'Class Code5',
                                                        ].includes(e.name)
                                                      ) {
                                                        const findObjectNumber: any = {
                                                          'Class Code1': 1,
                                                          'Class Code2': 2,
                                                          'Class Code3': 3,
                                                          'Class Code4': 4,
                                                          'Class Code5': 5,
                                                        };
                                                        const findNumber = findObjectNumber[e.name];
                                                        const findClass: any =
                                                          reportModal.coloumData.find(
                                                            (item: any) =>
                                                              item.classificationCode ===
                                                              findNumber,
                                                          );
                                                        if (findClass) {
                                                          e.name = findClass.mapping;
                                                        } else {
                                                          findNumber === 1
                                                            ? (e.name = 'Department')
                                                            : findNumber === 2
                                                              ? (e.name = 'Location')
                                                              : findNumber === 3
                                                                ? (e.name = 'Division')
                                                                : findNumber === 4
                                                                  ? (e.name = 'Shift')
                                                                  : findNumber === 5 &&
                                                                    (e.name = 'ProjectCostCenter');
                                                        }
                                                      }
                                                      return e;
                                                    })
                                                  : reportModal.keyName === 'OverAllSurveyResults'
                                                    ? OverallSurveyResults
                                                    : reportModal.keyName === 'ScoresbyCategory'
                                                      ? ScoresbyCategory
                                                      : reportModal.keyName === 'TimetoFill'
                                                        ? TimetoFill
                                                        : reportModal.keyName === 'OpenRequisition'
                                                          ? OpenRequisition
                                                          : reportModal.keyName === 'hiredCandidates'
                                                            ? hiredCandidates
                                                            : reportModal.keyName === 'OverallBreakdown'
                                                              ? OverallBreakdown
                                                              : reportModal.keyName === 'SurveySubmissionRate'
                                                                ? SurveySubmissionRate
                                                                : reportModal.keyName === 'offeredStart'
                                                                  ? offeredStart
                                                                  : []
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Reports;
