import { Typography, Grid, IconButton, Menu, MenuItem, Tooltip as TooltipMUI } from '@mui/material';
import React, { useEffect, useRef, useContext } from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import jsPDF from 'jspdf';
import InfoIcon from 'assets/png/info.png';
import fileDownload from 'js-file-download';
import domtoimage from 'dom-to-image';
import moment from 'moment';
// import * as R from 'ramda';
import Logo from 'assets/png/LogoDarkPng.png';
import { useAppSelector } from 'store/hooks';
import { getAllListData } from 'services/CommonServices';
import { COLORS, RECRUITING_DASHBOARD } from 'utils/Constant';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ScreenLoader from 'components/ScreenLoader';
import { FunnelChart } from 'chartjs-chart-funnel';
import { Chart } from 'chart.js';
import * as R from 'ramda';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { EyeToolTipTextOverAll, LastYearData } from 'utils/Config';
import { FilterDataContext } from 'context/FilterDataContextProvider';
Chart.register(ChartDataLabels);
// Chart.register([Tooltip1]);

const Recruiting = () => {
  const downloadRef0: any = useRef(null);
  const downloadRef1: any = useRef(null);
  const downloadRef2: any = useRef(null);
  const downloadRef3: any = useRef(null);
  const { filterData, showNumbers } = useContext(FilterDataContext);
  const { userInfo: userDetail, profileInfo }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });

  const [candidateActivity, setCandidateActivity] = React.useState<{
    data: any;
    originalData: any;
    names: any;
    loading: boolean;
  }>({
    data: [],
    originalData: [],
    names: [],
    loading: true,
  });
  const [candidateScore, setCandidateScore] = React.useState<{
    data: any;
    total: number;
    loading: boolean;
  }>({
    data: [],
    total: 0,
    loading: true,
  });
  const [averageTime, setAverageTime] = React.useState<{
    data: any;
    loading: boolean;
  }>({
    data: [],
    loading: true,
  });

  const [openRecruiting, setOpenRecruiting] = React.useState<{
    data: any;
    loading: boolean;
  }>({
    data: [],
    loading: true,
  });

  const [hideIcon, setHideIcon] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dwnldIndex, setDwnldIndex] = React.useState<any>({
    index: 0,
    name: '',
  });

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, idx: number, name: string) => {
    setAnchorEl(event.currentTarget);
    setDwnldIndex({
      index: idx,
      name: name,
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDwnldIndex({
      index: 0,
      name: '',
    });
  };
  
  useEffect(() => {
    if (
      candidateActivity?.data &&
      candidateActivity?.data?.length &&
      document.getElementById('funnelRef')
    ) {
      const funnelId: any = document.getElementById('funnelRef');
      if(FunnelChart.getChart(funnelId.getContext('2d'))) {
        FunnelChart.getChart(funnelId.getContext('2d'))?.destroy()
      }
      new FunnelChart(funnelId.getContext('2d'), {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: 'y'
        },
        plugins: [ChartDataLabels],
        data: {
          labels: candidateActivity?.names,
          datasets: [
            {
              data: [100, 85, 65, 45, 25],
              // data: candidateActivity?.data,
              shrinkAnchor: 'none',
              datalabels: {
                // anchor: (context: any) => {
                //   const value: number = context.dataset.data[context.dataIndex];
                //   return value < 0.02 ? 'end' : 'start';
                // },
                // align: (context) => {
                //   const value = context.dataset.data[context.dataIndex];
                //   return value < 0.02 ? 'end': 'center';
                // },
                // textAlign: 'start',
                color: '#fff',
                font: {
                  size: 14,
                },
                formatter: (value: any, context: any) => {
                  const label = context.chart.data.labels[context.dataIndex];
                  const per = candidateActivity?.originalData?.[context.dataIndex];
                  return label ? `${per.toLocaleString()}\n${label}` : '';
                },
              },
              backgroundColor: ['#C60C68', '#171A33', '#3441D2', '#0061FF', '#C3D34F'],
            },
          ],
        },
      });
    }
  }, [candidateActivity?.data]);

  const getApiChart = async () => {
    getAllListData(`${RECRUITING_DASHBOARD.AverageTime}/${userDetail.ClientId}/${userDetail.Id}`)
      .then((res) => {
        setAverageTime((_prev) => {
          return {
            ..._prev,
            data: res?.data,
            loading: false,
          };
        });
      })
      .catch(() => {
        setAverageTime((_prev) => {
          return {
            ..._prev,
            loading: false,
          };
        });
      });
    getAllListData(
      `${RECRUITING_DASHBOARD.CandidateActivity}/${userDetail.ClientId}/${userDetail.Id}`,
    )
      .then((res) => {
        if (
          res?.data?.applicants ||
          res?.data?.hired ||
          res?.data?.interviewed ||
          res?.data?.offered ||
          res?.data?.phoneScreened
        ) {
          const CandidateActivityData = [
            { name: 'Applicants', value: res.data?.applicants, id: '1' },
            { name: 'Phone Screens', value: res.data?.phoneScreened, id: '2' },
            { name: 'Hires ', value: res.data?.hired, id: '5' },
            { name: 'Interviews', value: res.data?.interviewed, id: '3' },
            { name: 'Offers', value: res.data?.offered, id: '4' },
          ];
          const sortedData = CandidateActivityData.sort(function (a: any, b: any) {
            return a.id - b.id;
          });
          const namesData: any = [];
          const valueData: any = [];
          sortedData.map((item: any) => {
            namesData.push(item.name);
            valueData.push(item.value);
          });
          const maxVal = Math.max(...valueData);
          const filterMinData = valueData.map((item: any) => {
            if ((item / maxVal) * 100 > 25) {
              return (item / maxVal) * 100;
            } else {
              return 25;
            }
          });
          setCandidateActivity((_prev) => {
            return {
              ..._prev,
              data: filterMinData,
              originalData: valueData,
              names: namesData,
              loading: false,
            };
          });
        } else {
          setCandidateActivity((_prev) => {
            return {
              ..._prev,
              loading: false,
            };
          });
        }
      })
      .catch(() => {
        setCandidateActivity((_prev) => {
          return {
            ..._prev,
            loading: false,
          };
        });
      });
    getAllListData(
      `${RECRUITING_DASHBOARD.CandidateByScore}/${userDetail.ClientId}/${userDetail.Id}`,
    )
      .then((res) => {
        const result = R.sortWith([R.descend(R.compose(R.prop('total')))]);
        setCandidateScore((_prev) => {
          return {
            ..._prev,
            data: result(res?.data?.sources ? res?.data?.sources : []),
            total: res?.data?.sourceTotal,
            loading: false,
          };
        });
      })
      .catch(() => {
        setCandidateScore((_prev) => {
          return {
            ..._prev,
            loading: false,
          };
        });
      });
    getAllListData(`${RECRUITING_DASHBOARD.OpenRecruiting}/${userDetail.ClientId}/${userDetail.Id}`)
      .then((resp: any) => {
        const data = R.map(
          (row: any) => {
            row['dateName'] = moment(row.date).format('MMM');
            return { ...row };
          },
          resp?.data,
        );
        setOpenRecruiting((_prev) => {
          return {
            ..._prev,
            data,
            loading: false,
          };
        });
      })
      .catch(() => {
        setOpenRecruiting((_prev) => {
          return { ..._prev, loading: false };
        });
      });
  };

  const handleDownload = (type: string, name: string, index: number) => {
    setHideIcon(true);
    const refOption =
      index === 0
        ? downloadRef0.current
        : index === 1
          ? downloadRef1.current
          : index === 2
            ? downloadRef2.current
            : downloadRef3.current;
    if (type === 'img') {
      domtoimage.toBlob(refOption).then(function (blob) {
        fileDownload(
          blob,
          `${name}-${profileInfo?.clientName}-${moment().format('MM/DD/YYYY')}.png`,
        );
        setHideIcon(false);
      });
    } else {
      domtoimage.toBlob(refOption).then(function (blob) {
        const newImg = new Image();
        newImg.onload = function () {
          const height = newImg.height;
          const width = newImg.width;
          const LogoSrc: any = newImg.src;
          const doc = new jsPDF();
          doc.setFontSize(10);
          doc.text(`Downloaded Date: ${moment().format('MM-DD-YYYY')}`, 10, 10);
          doc.text(`Company Name: ${profileInfo?.clientName}`, 10, 18);
          doc.addImage(
            LogoSrc,
            'png',
            30,
            30,
            width / 4 > 150 ? 150 : width / 4,
            height / 4 > 287 ? 250 : height / 4,
          );
          const img = new Image();
          const src = Logo;
          img.src = src;
          doc.addImage(img, 'png', 100, 287, 40, 5);
          doc.setFontSize(10);
          doc.text('Powered by ', 80, 290);
          doc.save(`${name}-${profileInfo?.clientName}-${moment().format('MM/DD/YYYY')}.pdf`);
          setHideIcon(false);
        };
        const imgSrc = URL.createObjectURL(blob);
        newImg.src = imgSrc;
      });
    }
    handleClose();
  };

  const CustomHeadCountTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'}>{`${moment(payload[0].payload.date).format(
            'MMMM YYYY',
          )}`}</Typography>
          <Typography component={'p'} className='qns-blue'>
            Total: {Math.round(payload[0].payload.totalDepartment)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const candidateScoreLegend = () => {
    return (
      <Typography component={'div'} className='legend-ul'>
        {showNumbers ? 
        (<li>
            <Typography component={'div'} className='d-flex flex-sb col-10'>
              <Typography className=' d-flex'>
                <Typography component={'div'}>
                  <Typography />
                </Typography>
                <Typography className='leg-info' sx={{ marginTop: '-5px' }}></Typography>
              </Typography>
              <Typography component={'div'} className='d-flex-a mb-15 col-2 col-2'>
                <Typography className='app-title mr-5' sx={{ textAlign: 'right', width: '43px' }} >Hired</Typography>
                <Typography className='app-title ml-10' sx={{ marginTop: '-5px', textAlign: 'right', width: '75px' }} >Total</Typography>
              </Typography>
            </Typography>
          </li>) :
          (<li>
            <Typography component={'div'} className='d-flex flex-sb col-10'>
              <Typography className=' d-flex'>
                <Typography component={'div'}>
                  <Typography />
                </Typography>
                <Typography className='leg-info' sx={{ marginTop: '-5px' }}></Typography>
              </Typography>
              <Typography component={'div'} className='d-flex-a mb-15 col-2 col-2'>
                <Typography className='app-title mr-5' sx={{ textAlign: 'right', width: '43px' }} ></Typography>
                <Typography className='app-title ml-10' sx={{ marginTop: '-5px', textAlign: 'right', width: '75px' }} >Total</Typography>
              </Typography>
            </Typography>
          </li>)}
          
        {candidateScore?.data.map((entry: any, index: any) => (          
          <li key={`item-${index}`}>
            <Typography component={'div'} className='d-flex flex-sb col-10'>
              <Typography component={'div'} className=' d-flex'>
                <Typography component={'div'}>
                  <Typography
                    component={'div'}
                    sx={{ backgroundColor: `${COLORS[index]}` }}
                    className='legend-dot'
                  />
                </Typography>
                <Typography className='leg-info' sx={{ marginTop: '-5px' }}>
                  {entry?.source}
                </Typography>
              </Typography>
              <Typography component={'div'} className='d-flex-a mb-15 col-2'>
                {showNumbers ? (<Typography className='app-title mr-5' sx={{ textAlign: 'right', width: '43px' }} >{entry?.hires}</Typography>) : (<Typography className='app-title mr-5' sx={{ textAlign: 'right', width: '43px' }} ></Typography>)}
                {showNumbers ? (<Typography className='app-title ml-10' sx={{ marginTop: '-5px', textAlign: 'right', width: '75px' }} >{entry?.total}</Typography>) : (<Typography className='app-title ml-10' sx={{ marginTop: '-5px', textAlign: 'right', width: '75px' }} >{entry?.percent}</Typography>)}
              </Typography>
            </Typography>
          </li>)
        )}
      </Typography>      
    );
  };

  const CustomCandidateScoreTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      if(showNumbers)
      {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.source}: {Math.round(payload[0].payload.total)}
          </Typography>
        </Typography>
      )
    }
    else{
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.source}: {payload[0].payload.percent}
          </Typography>
        </Typography>
      )
    };
    }
    return null;
  };

  const AverageTimeLegend = () => {
    return (
      <Typography component={'div'} className='d-flex-a'>
        <Typography component={'div'} className='d-flex-a hr-text'>
          <Typography className='s-dot' sx={{ backgroundColor: '#C3D34F' }} />
          Time to Fill
        </Typography>
      </Typography>
    );
  };

  const CustomAverageTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'}>{`${moment(payload[0].payload.yearMonth).format(
            'MMMM YYYY',
          )}`}</Typography>
          <Typography component={'p'} className='qns-green'>
            Average Time: {Math.round(payload[0].payload.averageTime)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const CustomLabel = ({ viewBox }: any) => {
    let averageTotal = 0;
    if (candidateScore?.data?.length) {
      const averagePrice = Number(candidateScore?.total);
      averageTotal = Math.round(averagePrice);
    }

    return (
      <text
        x={viewBox.cx}
        y={viewBox.cy - 10}
        fill='#3d405c'
        style={{ outline: 'none !important' }}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'>
        <tspan
          style={{
            outline: 'none !important',
          }}
          alignmentBaseline='middle'
          className={
            String(averageTotal).length > 5 ? 'total-pie total-pie-extra-text' : 'total-pie'
          }>
          {averageTotal}
        </tspan>
        <tspan
          style={{ outline: 'none !important' }}
          x={viewBox.cx}
          y={viewBox.cy + 20}
          className='ave-pie'>
          {'Total'}
        </tspan>
      </text>
    );
  };

  const renderCustomizedLabel = ({ x, y, width, value }: any) => {
    if (value) {
      return (
        <g>
          <text
            x={x + width / 2}
            y={y + 20}
            fill='#fff'
            textAnchor='middle'
            fontSize={'10px'}
            dominantBaseline='middle'>
            {value}
          </text>
        </g>
      );
    }
    return <Typography />;
  };

  useEffect(() => {
    getApiChart();
  }, [filterData]);

  React.useEffect(() => {
    window.scrollTo(0, 0);

 //   const node = document.querySelectorAll(' p * div ');
 //   console.log(node);
  }, [])

  return (
    <Grid container>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={() => handleDownload('img', dwnldIndex.name, dwnldIndex.index)}>
          Image
        </MenuItem>
        <MenuItem onClick={() => handleDownload('pdf', dwnldIndex.name, dwnldIndex.index)}>
          PDF
        </MenuItem>
      </Menu>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='ov-box'>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='pie-box' ref={downloadRef0}
                sx={{ minHeight: '410px !important' }}>
                {!hideIcon ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 0, 'Opened Requisitions')}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a mb-15'>
                  Opened Requisitions&nbsp;&nbsp;
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.recruiting.openRequisitions}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {openRecruiting?.loading ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={openRecruiting?.loading} />
                  </Typography>
                ) : !openRecruiting?.data?.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <Typography component={'div'} className='d-flex'>
                    <ResponsiveContainer width={'100%'} height={320} aspect={2.0}>
                      <BarChart
                        className='hrmonth-chart'
                        data={openRecruiting?.data}
                        stackOffset='sign'
                        margin={{
                          top: 20,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}>
                        <CartesianGrid stroke='white' />
                        <XAxis
                          dataKey='dateName'
                          height={60}
                          strokeDasharray='0 10'
                          label={{ value: LastYearData, dx: 10, dy: 15 }}
                          fontSize={12}
                          interval={0}
                        />
                        <YAxis strokeDasharray='0 10' />
                        <Tooltip content={CustomHeadCountTooltip} />
                        {/* <Legend verticalAlign='top' align='right' /> */}
                        <Bar dataKey='totalDepartment' fill='#C3D34F'>
                          {hideIcon && (
                            <LabelList dataKey='totalDepartment' content={renderCustomizedLabel} />
                          )}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Typography>
                )}
              </Typography>
              <Typography component={'div'} ref={downloadRef1} className='pie-box'
               sx={{ minHeight: '410px !important' }}>
                {!hideIcon ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 1, 'Average Time to Fill')}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a mb-15'>
                  Average Time to Fill&nbsp;&nbsp;
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.recruiting.averageTimetoFill}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {averageTime.loading ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={averageTime.loading} />
                  </Typography>
                ) : !averageTime?.data?.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <Typography component={'div'} className='d-flex'>
                    <ResponsiveContainer width='100%' height={320} aspect={2} >
                      <AreaChart
                        data={averageTime?.data}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 0,
                          bottom: 0,
                        }}>
                        <CartesianGrid strokeDasharray='0 10' />
                        <XAxis
                          dataKey='yearMonth'
                          tickFormatter={(e) => moment(e).format('MMM')}
                          label={{ value: LastYearData, dx: 10, dy: 15 }}
                          fontSize={12}
                          height={60}
                          interval={0}
                        />
                        <YAxis />
                        <Tooltip content={CustomAverageTooltip} />
                        <Area
                          type='monotone'
                          dataKey='averageTime'
                          stroke='#C3D34F'
                          strokeWidth={3}
                          fill='#C3D34F'
                        />
                        <Legend
                          verticalAlign='top'
                          align='right'
                          content={AverageTimeLegend()}
                          style={{ paddingLeft: '120px', paddingBottom: '20px' }}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='pie-box' ref={downloadRef2}
                   sx={{ minHeight: '410px !important' }}>
                {!hideIcon && candidateActivity?.data?.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 2, 'Candidate Activity ')}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a mb-15'>
                  Candidate Activity&nbsp;&nbsp;
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.recruiting.candidateActivity}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {candidateActivity.loading ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={candidateActivity.loading} />
                  </Typography>
                ) : !candidateActivity?.data?.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <Typography component={'div'} sx={{ paddingTop: '10px' }}>
                    <canvas id='funnelRef' height={200}></canvas>
                  </Typography>
                )}
              </Typography>
              <Typography ref={downloadRef3} component={'div'} className='pie-box'
               sx={{ minHeight: '410px !important' }}>
                {!hideIcon && candidateScore?.data?.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 3, 'Candidate by Source ')}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a mb-15'>
                  Candidate by Source &nbsp;&nbsp;
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.recruiting.candidatesbySource}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {candidateScore.loading ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={candidateScore.loading} />
                  </Typography>
                ) : !candidateScore?.data?.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <Typography component={'div'} className='d-flex'>
                    <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                      <ResponsiveContainer width={'100%'} height={'100%'}>
                        <PieChart>
                          <Tooltip content={<CustomCandidateScoreTooltip />} />
                          <Pie
                            data={candidateScore?.data}
                            cx='50%'
                            cy='50%'
                            innerRadius={60}
                            outerRadius={80}
                            fill='#8884d8'
                            paddingAngle={1}
                            dataKey='total'>
                            {candidateScore?.data.map((entry: any, index: number) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            <Label
                              width={30}
                              position='top'
                              content={<CustomLabel y={130} />}></Label>
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Typography>
                    <Typography component={'div'} className='flex-1'>
                      {candidateScoreLegend()}
                    </Typography>
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Recruiting;
