import React from 'react';
import { Navigate, Route, Routes, To, useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes as AdminPrivateRoutesDirectives, PublicRoutes } from './routeConstans';
import Company from 'pages/Admin/companies';
import Employees from 'pages/Admin/companies/employees';
import Classififcation from 'pages/Admin/companies/classification';
import PrivateLayout from 'pages/Admin';
import Admins from 'pages/Admin/admins';
import { useDispatch } from 'react-redux';
import { logout } from 'store/actions/UserAction';
// import moment from 'moment';
import { useIdleTimer } from 'react-idle-timer';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';

const AdminPrivateRoutes = () => {
  const dispatch: any = useDispatch();
  const { COMPANY, ADMINS, EMPLOYEES, CLASSIFICATION } = AdminPrivateRoutesDirectives;
  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const handleIdle = () => {
    logout(dispatch);
    setTimeout(() => gotoNavigate(PublicRoutes.ADMINLOGIN), 0);
    toast.error('Session expired! Please login again.', alertOptions);
  };
  useIdleTimer({
    timeout: 3600000,
    // timeout: 10000,

    //  promptTimeout: 5000 / 2,
    //  onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
  });


  return (
    <Routes>
      <Route path='/' element={<Navigate to={COMPANY} replace />} />
      <Route
        path={COMPANY}
        element={
          <PrivateLayout path={COMPANY}>
            <Company />
          </PrivateLayout>
        }
      />
      <Route
        path={`${EMPLOYEES}/:companyId`}
        element={
          <PrivateLayout path={EMPLOYEES}>
            <Employees />
          </PrivateLayout>
        }
      />
      <Route
        path={`${CLASSIFICATION}/:companyId`}
        element={
          <PrivateLayout path={CLASSIFICATION}>
            <Classififcation />
          </PrivateLayout>
        }
      />
      <Route
        path={ADMINS}
        element={
          <PrivateLayout path={ADMINS}>
            <Admins />
          </PrivateLayout>
        }
      />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default AdminPrivateRoutes;
