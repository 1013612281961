import {
  Grid,
  // InputAdornment, TextField
  Typography,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import React, { useEffect, useRef } from 'react';
import { EE_DASHBOARD, MASTER_ENDPOINT } from 'utils/Constant';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import PageLoader from 'components/PageLoader';
import { Network } from 'vis-network';
// import { Search } from '@mui/icons-material';
import { DataSet } from 'vis-data';
import * as R from 'ramda';
import PlusPng from 'assets/png/plus.png';
import EmployeeSearchContext from 'context/employeeSearchContext';
import EmployeeDetailPopOut from 'components/EmployeeDetailPopOut';

// const staticLogo = 'https://freepngimg.com/download/man/22654-6-man.png';
// const staticLogo1 =
//   'https://pngimages.in/uploads/png-compressd/2023/April-2023/student_Image_Transparent.png';
// const staticLogo2 =
//   'https://pngimages.in/uploads/png-compressd/2023/May-2023/businessman_Png_Images.png';
const urlStrat = 'https://ui-avatars.com/api/?size=80&name=';
const urlEnd = '&font-size=0.5&color=FFFFFF&length=1';
// const urlEnd = '&font-size=0.5&color=FFFFFF&background=%23AA0000&length=1';

const EmployeeEgage = () => {
  const chartRef: any = useRef<HTMLDivElement>(null);
  const [drawer, setDrawer] = React.useState(false);
  const [nodes, setNodes] = React.useState<any>([]);
  const [edges, setEdges] = React.useState<any>([]);
  const [overallMagicScore, setOverallMagicScore] = React.useState<any>({});
  const [magicScore, setMagicScore] = React.useState<any>([]);
  const [details, setDetails] = React.useState<any>([]);
  const [moreDetails, setMoreDetails] = React.useState<any>([]);
  const [viewMore, setViewMore] = React.useState<any>({});
  const [viewMoreDetails, setViewMoreDetails] = React.useState<any>({});
  const [loader, setLoader] = React.useState<boolean>(true);
  const [loader1, setLoader1] = React.useState<boolean>(true);
  
  const {selectedEmployeeId} = React.useContext(EmployeeSearchContext);

  const { profileInfo, userInfo: userDetail }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });

   useEffect(() => {
     if(selectedEmployeeId === null || selectedEmployeeId === 0){
      setLoader(true);
      getAllListData(`${EE_DASHBOARD.OrgChart1}/${profileInfo?.clientID}/${userDetail?.Id}`)
        .then((resp: any) => {
          if (resp?.data?.networkGraph) {
            const companyData: any = [
              {
                id: 'rootNode',
                label: profileInfo?.clientName,
                shape: 'circularImage',
                level: 0,
                image: `${urlStrat}${profileInfo?.clientName}${urlEnd}`,
                overallMagicScore: resp?.data?.overallMagicScore,
                magicScore: resp?.data?.magicScore
              },
            ];
            const edgesData: any = [];
            addNodeData(companyData, edgesData, 1, null, 'rootNode', resp?.data?.networkGraph);
            
            setEdges(new DataSet(edgesData));
            setNodes(new DataSet(companyData));

            setViewMoreDetails({employeeId: 0, image: ''});
            setDetails({});
          }
          setOverallMagicScore(resp?.data?.overallMagicScore ? resp?.data?.overallMagicScore : {});
          setMagicScore(resp?.data?.magicScore ? resp?.data?.magicScore : []);        
        })
        .finally(() => {
          setLoader(false);
        });
      }
      else{        
        setLoader(true);
        getAllListData(`${EE_DASHBOARD.GetAllDirectReports}/${profileInfo?.clientID}/${userDetail?.Id}/${selectedEmployeeId}`)
        .then((resp: any) => {
          if (resp?.data?.networkGraph) {
            const nodeData: any = [
              {
                id: 'rootNode',
                label: profileInfo?.clientName,
                shape: 'circularImage',
                level: 0,
                image: `${urlStrat}${profileInfo?.clientName}${urlEnd}`,
                overallMagicScore: resp?.data?.overallMagicScore,
                magicScore: resp?.data?.magicScore
              },
            ];
            const edgesData: any = [];
            addNodeData(nodeData, edgesData, 1, null, 'rootNode', resp?.data?.networkGraph);
 
            // Set node item to allow for View Details
            if (selectedEmployeeId !== 0) {            
              const nodeItem = nodeData.find((n:any) => n.employeeId == selectedEmployeeId);

              if(nodeItem){       
                setViewMoreDetails({ ...nodeItem });
                const url = `${EE_DASHBOARD.ViewMore}/${userDetail.ClientId}/${userDetail.Id}/${selectedEmployeeId}`;
                setLoader(true);
                                
                getAllListData(url)
                  .then((res) => {
                    setDetails(res?.data);
                  })
                  .finally(() => {
                    setLoader(false);
                  });
               }
            }

            setEdges(new DataSet(edgesData));            
            setNodes(new DataSet(nodeData));
          }

          setLoader(true);
          getAllListData(
            `${EE_DASHBOARD.OrgChart2}/${profileInfo?.clientID}/${selectedEmployeeId}/${userDetail?.Id}`,
          )
            .then((resp: any) => {
              if (resp?.data?.networkGraph) {               
                setOverallMagicScore(
                  resp?.data?.overallMagicScore ? resp?.data?.overallMagicScore : {},
                );
                setMagicScore(resp?.data?.magicScore ? resp?.data?.magicScore : []);
              }
            })
            .finally(() => {
              setLoader(false);
            });
          
        })
        .finally(() => {
          setLoader(false);
        });
      }
           
  }, [selectedEmployeeId]); 

  useEffect(() => {
    if (nodes?.length && edges?.length) {

      // Tell VIS parser to use colors
      nodes.parseColor = true;
      
      const data = {
        nodes,
        edges,
      };

      let employeeId = 0;

      const options = {
        nodes: {
          shadow: true,
          borderWidth: 5,
          borderWidthSelected: 10,
          color: {
            border: '#3441d2',
            // background: '#3441d2',
            // highlight: { border: '#3441d2', background: '#3441d2' },
            // hover: { border: '#3441d2', background: '#3441d2' },
          },
          font: {
            size: 15,
            color: '#171A33',
            strokeWidth: 3,
          },
        },
        edges: {
          shadow: true,
          color: '#171A33',
          arrows: {
            to: {
              enabled: true,
              type: 'circle',
              scaleFactor: 0.3,
            },
          },
        },
      };

      const network = new Network(chartRef.current, data, options);

      // Try to get a node based on the node id
      if(selectedEmployeeId){
        const nodeLst = nodes.get({
          filter: function(n:any){
            return n.employeeId == selectedEmployeeId;
          }
        });
        if(nodeLst?.length > 0){
          network.selectNodes([nodeLst[0].id]);
        }                
      };
           
      network.on('oncontext', (event: any) => {
        event.event.preventDefault();
        const nodeId: any = network.getNodeAt(event.pointer.DOM);
        const nodeItem = nodes.get(nodeId);
        if (nodeItem?.level !== 0) {
          if (nodeId) {
            setViewMore({
              nodeId,
              left: event.event.pageX,
              top: event.event.pageY,
            });
            setViewMoreDetails({ ...nodeItem });
          }
        } else {
          setViewMore({});
        }
      });      
      network.on('click', (event: any) => {
        setViewMore({});        
        const [clickedNode] = event.nodes;
        if (clickedNode) {       
          const edgesData = edges.get({
            filter: (item: any) => item.from === clickedNode,
          });

          const nodeItem = nodes.get(clickedNode);
          // Set node item to allow for View Details
          if (nodeItem?.level !== 0 && nodeItem.employeeId !== employeeId) {
            employeeId = nodeItem.employeeId;

            if (nodeItem.id && nodeItem.employeeId) {
              setViewMoreDetails({ ...nodeItem });
              const url = `${EE_DASHBOARD.ViewMore}/${userDetail.ClientId}/${userDetail.Id}/${nodeItem.employeeId}`;
              setLoader(true);
              
              getAllListData(url)
                .then((res) => {
                  setDetails(res?.data);
                })
                .finally(() => {
                  setLoader(false);
                });
            }
          }

          if (edgesData.length && nodeItem?.employeeId) {
            if(!nodeItem?.overallMagicScore){
              getAllListData(
                `${EE_DASHBOARD.OrgChart2}/${profileInfo?.clientID}/${nodeItem?.employeeId}/${userDetail?.Id}`,
              )
                .then((resp: any) => {
                   setOverallMagicScore(
                      resp?.data?.overallMagicScore ? resp?.data?.overallMagicScore : {},
                    );
                    setMagicScore(resp?.data?.magicScore ? resp?.data?.magicScore : []);
                    // network.focus(clickedNode);
                  })
                .finally(() => {
                  setLoader(false);
                });
            }
            setOverallMagicScore(nodeItem?.overallMagicScore ? nodeItem?.overallMagicScore : {});
            setMagicScore(nodeItem?.magicScore ? nodeItem?.magicScore : []);
            // const connected = network.getConnectedNodes(clickedNode);
            const child: any = nodes.get(edgesData[0].to);
            const hideOrNot: boolean = !child?.hidden;
            const checkNodes = (node: any, hideOrNot: boolean) => {
              if (!node) {
                return;
              }
              if (node && node.length > 0) {
                for (let i = 0; i < node.length; i++) {
                  const child: any = nodes.get(node[i].to);
                  // const hideOrNot: boolean = !child?.hidden;
                  nodes.update({ ...child, hidden: hideOrNot });
                  const edgesFindData = edges.get({
                    filter: (item: any) => item.from === node[i].to,
                  });
                  checkNodes(edgesFindData, hideOrNot);
                }
              }
            };
            checkNodes(edgesData, hideOrNot);
            return;
          }         

          if(nodeItem?.employeeId){
          setLoader(true);
          getAllListData(
            `${EE_DASHBOARD.OrgChart2}/${profileInfo?.clientID}/${nodeItem?.employeeId}/${userDetail?.Id}`,
          )
            .then((resp: any) => {
              if (resp?.data?.networkGraph) {
                const nodesData: any = [];
                const edgesData: any = [];
                addNodeData(nodesData, edgesData, 2, nodeItem?.classCodeId, nodeItem.id, resp?.data?.networkGraph);
                
                nodes.add(nodesData);
                edges.add(edgesData);  
                let existingNode = null;
                for(let i = 0; i < nodes.length; i++)
                {
                  const node = nodes[i];
                  if(nodeItem?.id && node?.id?.startsWith(nodeItem?.employeeId?.toStriong())){
                    existingNode = node;
                    break;
                  }
                }
                if(existingNode) {                     
                  nodes.update({
                    ...nodeItem,
                    overallMagicScore: resp?.data?.overallMagicScore,
                    magicScore: resp?.data?.magicScore,                   
                  });
                }
                setOverallMagicScore(
                  resp?.data?.overallMagicScore ? resp?.data?.overallMagicScore : {},
                );
                setMagicScore(resp?.data?.magicScore ? resp?.data?.magicScore : []);
                // network.focus(clickedNode);
              }
            })
            .finally(() => {
              setLoader(false);
            });
          }
        }
        // }
      });
    }
  }, [nodes, edges]);

  const addNodeData = (nodesData:any, edgesData:any, nodeLevel:number, nodeClassCodeId: any, parentNode: any,  inputNodes:any) => {    
    inputNodes.map((item: any) => {
      const today = new Date();
      const newId = Number(item.employeeId).toString() + today.getSeconds().toString() + today.getMilliseconds().toString() + Math.random().toString() ;
                 
        const colorCode =
          item?.status === 'Good Standing'
            ? '#344A5C'
            : item?.status === 'Needs Attention'
              ? '#C60C68'
              : item?.status === 'On the Move'
                ? '#C3D34F'
                : '#CCCDD2';
        const colorCode1 =
          item?.status === 'Good Standing'
            ? '344A5C'
            : item?.status === 'Needs Attention'
              ? 'C60C68'
              : item?.status === 'On the Move'
                ? 'C3D34F'
                : 'CCCDD2';

        const colorCodeSelected =
                item?.status === 'Good Standing'
                  ? '#000000'
                  : item?.status === 'Needs Attention'
                    ? '#000000'
                    : item?.status === 'On the Move'
                      ? '#000000'
                      : '#000000';

          const newNode:any = {
            id: newId,  
            label: item.displayName,
            shape: 'circularImage',
            level: nodeLevel,
            classCodeId: nodeClassCodeId,
            overallMagicScore: item?.overallMagicScore,
            magicScore: item?.magicScore,
            image: item?.userImageId
              ? `${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${item?.userImageId}`
              : `${urlStrat}${item.lName}${urlEnd}&background=%23${colorCode1}`,
            ...item,
            color: {
              background: colorCode,
              border: colorCode,
              highlight: {
                border: colorCodeSelected,
                background: colorCodeSelected
              },
              hover: {
                border: colorCode,
                background: colorCode,
              }
            }
          };

          nodesData.push(
            newNode
          );

          edgesData.push({
            id: newId,  
            from: parentNode?.toString(),
            to: newId, 
            arrows: item.employeeCount
              ? {
                  to: {
                    enable: true,
                    type: 'image',
                    imageWidth: 17,
                    imageHeight: 17,
                    expand: true,
                    src: PlusPng,
                  },
                }
              : {},
          });

          if(item !== null && item?.children?.length > 0){
            addNodeData(nodesData, edgesData, 2, nodeClassCodeId, newId, item?.children);
          }         
    })
  };

  const handleToggle = async (toogleBoolean: boolean) => {
    setDrawer(toogleBoolean);
    setViewMore({});
    if (toogleBoolean) {
      const url = `${EE_DASHBOARD.ViewMore}/${userDetail.ClientId}/${userDetail.Id}/${viewMoreDetails.employeeId}`;
      setLoader1(true);
      await getAllListData(url)
        .then((res) => {
          setDetails(res?.data);
        })
        .finally(() => {
          setLoader1(false);
        });
      const UrlM = `${EE_DASHBOARD.ViewMoreMagic}/${userDetail.ClientId}/${userDetail.Id}/${viewMoreDetails.employeeId}`;
      await getAllListData(UrlM).then((res) => {
        setMoreDetails(res?.data);
      });
    }
  };

  return (
    <Grid container>
      <PageLoader loading={loader} />
      <Grid item md={12} sm={12} xs={12}>
        {}
        <Typography component={'div'} className='org-container'>
          {viewMore && Object.keys(viewMore).length ? (
            <Typography
              component={'div'}
              className='rmenu'
              sx={{ left: viewMore.left, top: viewMore.top }}>
              <Typography onClick={() => handleToggle(true)}>View More Details</Typography>
            </Typography>
          ) : (
            <Typography />
          )}

          <Typography component={'div'} style={{ height: '100%', width: '100%' }} ref={chartRef} />
        </Typography>
        <Typography component={'div'} className='d-flex-ja flex-column'>
          {}
          <EmployeeDetailPopOut loader1={loader1} 
                        setLoader1={setLoader1} 
                        drawer={drawer} 
                        userDetail={userDetail} 
                        details={details} 
                        viewMoreDetails={viewMoreDetails} 
                        moreDetails={moreDetails} 
                        setDrawer={setDrawer} 
                        setDetails={setDetails} 
                        setMoreDetails={setMoreDetails} ></EmployeeDetailPopOut> 
          { details?.employeeInformations?.[0]?.name !== undefined && 
                                    details?.employeeInformations?.[0]?.name !== null &&
          <Typography component={'div'} className="username_detailbox">
              <Typography className="nametext" component={'div'}>{details?.employeeInformations?.[0]?.name} </Typography>       
              <Typography className="viewdetails" onClick={() => handleToggle(true)} >View Details</Typography>
          </Typography>
          }
          <Typography component={'div'} className='org-total-box d-flex-a'>
            <Typography component={'div'} className='ot-box d-flex-j flex-column'>
              <Typography className='total-title'>Overall</Typography>
              {overallMagicScore && Object.keys(overallMagicScore).length ? (
                <Typography component={'div'} className='d-flex-a'>
                  <Typography className='total-num'>{overallMagicScore?.score}</Typography>
                  <Typography className='d-flex-a' component={'div'}>
                    <ArrowDownwardIcon
                      className={overallMagicScore?.scoreVariation >= 0 ? 'down-per' : 'up-per'}
                    />{' '}
                    <Typography className='per-txt'>
                      {overallMagicScore?.scorePercentage}%
                    </Typography>
                  </Typography>
                </Typography>
              ) : (
                <Typography component={'div'} className='d-flex-a' justifyContent={'center'}>
                  <Typography className='total-num'>-</Typography>
                </Typography>
              )}
            </Typography>
            <Typography component={'div'} className='flex-1'>
              <Grid container>
                <Grid item md={2.0} sm={12} xs={12}>
                  <Typography component={'div'} className='no-box d-flex-j flex-column'>
                    <Typography className='total-title'>Purpose</Typography>
                    {/* { Meaning changed has Purpose } */}
                    {R.findIndex(R.propEq('Meaning', 'value'))(magicScore ? magicScore : []) !==
                    -1 ? (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>
                          {
                            magicScore[
                              R.findIndex(R.propEq('Meaning', 'value'))(
                                magicScore ? magicScore : [],
                              )
                            ].score
                          }
                        </Typography>
                        <Typography className='d-flex-a' component={'div'}>
                          <ArrowDownwardIcon
                            className={
                              magicScore[
                                R.findIndex(R.propEq('Meaning', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scoreVariation >= 0
                                ? 'down-per'
                                : 'up-per'
                            }
                          />{' '}
                          <Typography className='per-txt'>
                            {
                              magicScore[
                                R.findIndex(R.propEq('Meaning', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scorePercentage
                            }
                            %
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>-</Typography>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={2.0} sm={12} xs={12}>
                  <Typography component={'div'} className='no-box d-flex-j flex-column'>
                    <Typography className='total-title'>Independence</Typography>
                    {/* { Autonomy has changed Independence } */}
                    {R.findIndex(R.propEq('Autonomy', 'value'))(magicScore ? magicScore : []) !==
                    -1 ? (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>
                          {
                            magicScore[
                              R.findIndex(R.propEq('Autonomy', 'value'))(
                                magicScore ? magicScore : [],
                              )
                            ].score
                          }
                        </Typography>
                        <Typography className='d-flex-a' component={'div'}>
                          <ArrowDownwardIcon
                            className={
                              magicScore[
                                R.findIndex(R.propEq('Autonomy', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scoreVariation >= 0
                                ? 'down-per'
                                : 'up-per'
                            }
                          />{' '}
                          <Typography className='per-txt'>
                            {
                              magicScore[
                                R.findIndex(R.propEq('Autonomy', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scorePercentage
                            }
                            %
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>-</Typography>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={2.0} sm={12} xs={12}>
                  <Typography component={'div'} className='no-box d-flex-j flex-column'>
                    <Typography className='total-title'>Growth</Typography>
                    {R.findIndex(R.propEq('Growth', 'value'))(magicScore ? magicScore : []) !==
                    -1 ? (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>
                          {
                            magicScore[
                              R.findIndex(R.propEq('Growth', 'value'))(magicScore ? magicScore : [])
                            ].score
                          }
                        </Typography>
                        <Typography className='d-flex-a' component={'div'}>
                          <ArrowDownwardIcon
                            className={
                              magicScore[
                                R.findIndex(R.propEq('Growth', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scoreVariation >= 0
                                ? 'down-per'
                                : 'up-per'
                            }
                          />{' '}
                          <Typography className='per-txt'>
                            {
                              magicScore[
                                R.findIndex(R.propEq('Growth', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scorePercentage
                            }
                            %
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>-</Typography>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={2.0} sm={12} xs={12}>
                  <Typography component={'div'} className='no-box d-flex-j flex-column'>
                    <Typography className='total-title'>Impact</Typography>
                    {R.findIndex(R.propEq('Impact', 'value'))(magicScore ? magicScore : []) !==
                    -1 ? (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>
                          {
                            magicScore[
                              R.findIndex(R.propEq('Impact', 'value'))(magicScore ? magicScore : [])
                            ].score
                          }
                        </Typography>
                        <Typography className='d-flex-a' component={'div'}>
                          <ArrowDownwardIcon
                            className={
                              magicScore[
                                R.findIndex(R.propEq('Impact', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scoreVariation >= 0
                                ? 'down-per'
                                : 'up-per'
                            }
                          />{' '}
                          <Typography className='per-txt'>
                            {
                              magicScore[
                                R.findIndex(R.propEq('Impact', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scorePercentage
                            }
                            %
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>-</Typography>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={2.0} sm={12} xs={12}>
                  <Typography component={'div'} className='no-box d-flex-j flex-column'>
                    <Typography className='total-title'>Relationship</Typography>
                    {/* {Connection has been changed Relationship} */}
                    {R.findIndex(R.propEq('Connection', 'value'))(magicScore ? magicScore : []) !==
                    -1 ? (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>
                          {
                            magicScore[
                              R.findIndex(R.propEq('Connection', 'value'))(
                                magicScore ? magicScore : [],
                              )
                            ].score
                          }
                        </Typography>
                        <Typography className='d-flex-a' component={'div'}>
                          <ArrowDownwardIcon
                            className={
                              magicScore[
                                R.findIndex(R.propEq('Connection', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scoreVariation >= 0
                                ? 'down-per'
                                : 'up-per'
                            }
                          />{' '}
                          <Typography className='per-txt'>
                            {
                              magicScore[
                                R.findIndex(R.propEq('Connection', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scorePercentage
                            }
                            %
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>-</Typography>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={2.0} sm={12} xs={12}>
                  <Typography component={'div'} className='no-box d-flex-j flex-column'>
                    <Typography className='total-title'>Capacity</Typography>
                    {/* { Autonomy has changed Capacity } */}
                    {R.findIndex(R.propEq('Capacity', 'value'))(magicScore ? magicScore : []) !==
                    -1 ? (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>
                          {
                            magicScore[
                              R.findIndex(R.propEq('Capacity', 'value'))(
                                magicScore ? magicScore : [],
                              )
                            ].score
                          }
                        </Typography>
                        <Typography className='d-flex-a' component={'div'}>
                          <ArrowDownwardIcon
                            className={
                              magicScore[
                                R.findIndex(R.propEq('Capacity', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scoreVariation >= 0
                                ? 'down-per'
                                : 'up-per'
                            }
                          />{' '}
                          <Typography className='per-txt'>
                            {
                              magicScore[
                                R.findIndex(R.propEq('Capacity', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scorePercentage
                            }
                            %
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>-</Typography>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmployeeEgage;
